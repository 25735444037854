import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { FiSettings } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useUser } from "../../userContext";

const Sidebar = () => {
    const location = useLocation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { pathname } = location;
    const { user, logout } = useUser();

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isMobile = windowWidth < 992;
    return (
        <Navbar bg="dark" expand="lg" variant="dark" className={!isMobile ? 'vh-100' : ''}>
            <Container className="vertical-navbar" style={{ alignItems: "start", padding: "15px 25px" }}>
                <Navbar.Brand href="/dashboard/home">
                    <Image src="/logo-white.svg" width={30} />
                    <Image src="/logo-text-white.svg" width={120} />
                </Navbar.Brand>
                <div className="mt-3 mb-4 d-none d-lg-block">
                    <div style={{
                        border: "5px solid white",
                        display: "inline-flex",
                        borderRadius: "100%",
                        padding: "5px",
                        position: 'relative',
                        marginBottom: 5
                    }} id="parent">
                        <Image src={user?.avatar} roundedCircle width={80} height={80} />
                        {/* <Link to={"#"}>
                            <div className="profile-edit-container" style={{
                                position: "absolute",
                                bottom: -10,
                                right: -5,
                                background: "#fff",
                                borderRadius: "100%",
                                padding: 10
                            }}>
                                <Image src="/images/icons/edit.png" width={20} height={20} />
                            </div>
                        </Link> */}
                    </div>
                    <h5 className="mt-2 mb-0 text-white">{user?.name}</h5>
                    <small className="text-white fontWeight-200">{user?.email}</small>
                </div>
                <Navbar.Toggle aria-controls="headerNavbarScroll" style={isMobile ? { position: 'absolute', right: 15 } : {}} />
                <Navbar.Collapse style={isMobile ? { width: "100%", marginTop: 15 } : { width: "100%" }} id="headerNavbarScroll">
                    <Nav className="me-auto vertical-navbar" style={{ width: "100%" }}>
                        <Nav.Link href="/dashboard/home" className={`${pathname === '/dashboard/home' ? 'selected' : ''}`}>
                            {pathname === '/dashboard/home' ? <Image src="/images/icons/dashboard-white.png" height={15} className="me-2" /> : <Image src="/images/icons/dashboard.png" height={15} className="me-2" />}
                            Dashboard
                        </Nav.Link>
                        <Nav.Link href="/dashboard/hotels" className={`${pathname === '/dashboard/hotels' ? 'selected' : ''}`}>
                            {pathname === '/dashboard/hotels' ? <Image src="/images/icons/user-white.png" height={15} className="me-2" /> : <Image src="/images/icons/user.png" height={15} className="me-2" />}
                            Hotels
                        </Nav.Link>
                        <Nav.Link href="/dashboard/rooms" className={`${pathname === '/dashboard/rooms' ? 'selected' : ''}`}>
                            {pathname === '/dashboard/rooms' ? <Image src="/images/icons/room-white.png" height={15} className="me-2" /> : <Image src="/images/icons/room.png" height={15} className="me-2" />}
                            Room
                        </Nav.Link>
                        <Nav.Link href="/dashboard/booking" className={`${pathname === '/dashboard/booking' ? 'selected' : ''}`}>
                            {pathname === '/dashboard/booking' ? <Image src="/images/icons/booking-white.png" height={15} className="me-2" /> : <Image src="/images/icons/booking.png" height={15} className="me-2" />}
                            Booking
                        </Nav.Link>
                        <Nav.Link href="/dashboard/guest-list" className={`${pathname === '/dashboard/guest-list' ? 'selected' : ''}`}>
                            {pathname === '/dashboard/guest-list' ? <Image src="/images/icons/user-white.png" height={15} className="me-2" /> : <Image src="/images/icons/user.png" height={15} className="me-2" />}
                            Guest
                        </Nav.Link>
                        <Nav.Link href="/dashboard/employee" className={`${pathname === '/dashboard/employee' ? 'selected' : ''}`}>
                            {pathname === '/dashboard/employee' ? <Image src="/images/icons/employee-white.png" height={15} className="me-2" /> : <Image src="/images/icons/employee.png" height={15} className="me-2" />}
                            Employee
                        </Nav.Link>
                        <Nav.Link href="/dashboard/settings" className={`${pathname === '/dashboard/settings' ? 'selected' : ''}`}>
                            <FiSettings className="me-2" /> Settings
                        </Nav.Link>
                        {/* Logout Link */}
                        <Nav.Link onClick={logout} className="text-danger">
                            <Image src="/images/icons/logout.png" height={15} className="me-2" />
                            Logout
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <div className="text-white mt-10 d-none d-lg-block">
                    <h5>TravelPinQ</h5>
                    <small style={{ fontWeight: 200, fontSize: 13 }}>&#169; 2023 All Rights Reserved</small>
                </div>
            </Container>
        </Navbar>
    );
}

const mapStateToProps = (state) => ({
    defaultActiveSidebarItem: state.dashboardInitialState.defaultActiveSidebarItem
});

const dispatchStateToProps = (dispatch) => ({
    toggleSidebarItem: (newItem) => {
        dispatch({
            type: "UPDATE_DASHBOARD_SIDEBAR_DEFAULT_ITEM",
            payload: newItem
        });
    }
});

export default connect(mapStateToProps, dispatchStateToProps)(Sidebar);
