import { Link, Outlet, useLocation } from "react-router-dom";

import Menu from "../includes/menu";
import {
  Button,
  Col,
  Container,
  FormControl,
  Image,
  Nav,
  NavDropdown,
  Navbar,
  Row,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import FooterDivider from "../includes/FooterDivider";
import CartIconWithBadge from "../includes/CartIconWithBadge";
import { useUser } from "../../userContext";

export default function LandingLayout(props) {
  const location = useLocation();
  const { pathname } = location;
  const { user, logout } = useUser();
  return (
    <>
      <Navbar
        expand="lg"
        style={{
          borderBottom: '1px solid rgba(255, 255, 255, 0.20)',
          background: 'rgba(0, 0, 0, 0.30)',
          backdropFilter: 'blur(9.899999618530273px)',
        }}>
        <Container>
          <Navbar.Brand href="/" className="d-block">
            <Image src={"./../logo-white.svg"} />
            <Image src={"./../logo-text-white.svg"} style={{ height: "30px", width: "auto", marginLeft: "10px" }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="headerNavbarScroll" />
          <Navbar.Collapse id="headerNavbarScroll">
            <div className="top-header--right-social-links-and-others">
              <Nav className="top-header--social-links">
                <Nav.Link>
                  <Link
                    to="/"
                    className="top-header--register-btn"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    PKR
                  </Link>
                </Nav.Link>
                {/* <Nav.Link>
                  <img src="./../images/ellipse-70.png" alt="facebook" style={{ width: "20px", marginRight: "4px" }} /> Select Lanuage
                </Nav.Link> */}
                <Nav.Link>
                  <img src="./../images/group-10000017940.svg" alt="facebook" style={{ width: "20px", marginRight: "4px" }} />Pin Your Property
                </Nav.Link>
                <Nav.Link>
                  <img src="./../images/vector0.svg" alt="facebook" style={{ width: "20px", marginRight: "4px" }} />
                </Nav.Link>
              </Nav>
              <Nav.Link className="signin-or-register-link">
                {user ? (
                  <>
                    <Link className="top-header--welcome-msg">
                      Welcome, {user.name}!
                    </Link>
                    <button
                      onClick={logout}
                      style={{
                        textDecoration: "none",
                        color: "white",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      Logout
                    </button>
                  </>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="top-header--sign-btn"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Sign in
                    </Link>
                    <span className="d-none d-lg-block d-md-block me-2 ml-2">
                      {" or "}
                    </span>
                    <Link
                      to="/signup"
                      className="top-header--register-btn"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Register
                    </Link>
                  </>
                )}
              </Nav.Link>
            </div>
            <Nav className="me-auto d-lg-none">
              <Nav.Link
                as={Link}
                to="/"
                className={`nav-link ${pathname === "/about-us" || pathname === "/contact-us"
                  ? "text-white "
                  : ""
                  }${pathname === "/home" ? "active" : ""}`}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/all-hotels"
                className={`nav-link ${pathname === "/about-us" || pathname === "/contact-us"
                  ? "text-white "
                  : ""
                  }${pathname === "/all-hotels" ? "active" : ""}`}
              >
                Hotel
              </Nav.Link>
              {/* <Nav.Link
                as={Link}
                to="/tour"
                className={`nav-link ${
                  pathname === "/about-us" || pathname === "/contact-us"
                    ? "text-white "
                    : ""
                }${pathname === "/tour" ? "active" : ""}`}
              >
                Tour
              </Nav.Link> */}
              <Nav.Link
                as={Link}
                to="/about-us"
                className={`nav-link ${pathname === "/about-us" || pathname === "/contact-us"
                  ? "text-white "
                  : ""
                  }${pathname === "/about-us" ? "active" : ""}`}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact-us"
                className={`nav-link ${pathname === "/about-us" || pathname === "/contact-us"
                  ? "text-white "
                  : ""
                  }${pathname === "/contact-us" ? "active" : ""}`}
              >
                Contact Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/cart"
                className={`nav-link ${pathname === "/about-us" || pathname === "/contact-us"
                  ? "text-white "
                  : ""
                  }${pathname === "/cart" ? "active" : ""}`}
              >
                <CartIconWithBadge itemCount={5} />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Menu />
      <div style={{ background: "rgba(70, 15, 31, 0.04)" }}>
        <Outlet />
      </div>
      <footer className="bg-dark text-white" id="myfooter">
        <Container style={{ paddingTop: 25, paddingBottom: 15 }}>
          <Row>
            <Col xs={12} md={3}>
              <h6 className="mb-4">Need My Travel Help?</h6>
              <Row>
                <Col xs={2} md={2}>
                  <img
                    src="./../images/phone-dailer.svg"
                    width={40}
                    alt="dailer"
                  />
                </Col>
                <Col xs={10} md={10}>
                  <Row>
                    <Col>Got Questions ? Call us 24/7!</Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className="custom-text-color">Call Us:</span>{" "}
                      <Link to={"#"} className="custom-text-color">
                        (888) 123456789
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <br />
              <br />
              <h6>Contact Info</h6>
              <p>
                PO Box CT16122 Collins Street <br /> West, Victoria
                8007,Australia.
              </p>
              <div className="d-flex align-items-center mb-5">
                <Button variant="outline-light" className="rounded-circle me-2">
                  <Image
                    src="./../images/facebook-white.png"
                    width={12}
                    height={12}
                  />
                </Button>
                <Button variant="outline-light" className="rounded-circle me-2">
                  <Image
                    src="./../images/twitter-white.png"
                    width={12}
                    height={12}
                  />
                </Button>
                <Button variant="outline-light" className="rounded-circle me-2">
                  <Image
                    src="./../images/instagram-white.png"
                    width={12}
                    height={12}
                  />
                </Button>
                <Button variant="outline-light" className="rounded-circle me-2">
                  <Image
                    src="./../images/linkedin-white.png"
                    width={12}
                    height={12}
                  />
                </Button>
              </div>
            </Col>
            <Col xs={12} md={2}>
              <h6 className="mb-4">Company</h6>
              <ul className="custom-footer-list">
                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                <li>
                  <Link to={"/about-us"}>About Us</Link>
                </li>
                <li>
                  <Link to={"#"}>Careers</Link>
                </li>
                <li>
                  <Link to={"#"}>Term of Use</Link>
                </li>
                <li>
                  <Link to={"#"}>Privacy Statement</Link>
                </li>
                <li>
                  <Link to={"#"}>Give Us Feedbacks</Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} md={2}>
              <h6 className="mb-4">Other Services</h6>
              <ul className="custom-footer-list">
                <li>
                  <Link to={"#"}>Investor Relations</Link>
                </li>
                <li>
                  <Link to={"#"}>Rewards Program</Link>
                </li>
                <li>
                  <Link to={"#"}>PointsPLUS</Link>
                </li>
                <li>
                  <Link to={"#"}>Partners</Link>
                </li>
                <li>
                  <Link to={"#"}>List My Hotel</Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} md={2}>
              <h6 className="mb-4">Support</h6>
              <ul className="custom-footer-list">
                <li>
                  <Link to={"#"}>Account</Link>
                </li>
                <li>
                  <Link to={"#"}>Legal</Link>
                </li>
                <li>
                  <Link to={"#"}>Contact</Link>
                </li>
                <li>
                  <Link to={"#"}>Affiliate Program</Link>
                </li>
                <li>
                  <Link to={"#"}>Privacy Policy</Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} md={3}>
              <h6 className="mb-4">Mailing List</h6>
              <p>
                Sign up for our mailing list to get
                <br />
                latest updates and offers
              </p>
              <Row>
                <Col xs={12} md={7} className="custom-right-padding-zero">
                  <FormControl
                    type="email"
                    placeholder="Your Email"
                    className="custom-email-form-control"
                  />
                </Col>
                <Col xs={12} md={5}>
                  <div className="d-grid gap-2">
                    <Button
                      variant="danger"
                      size="lg"
                      className="subscribes-btn"
                    >
                      Subscribes
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <FooterDivider />
        <Container>
          <Row>
            <Col xs={12} md={7}>
              <Image src="./../logo-white.svg" />
              <Image src="./../logo-text-white.svg" style={{ height: "30px", width: "auto", marginLeft: "10px" }} />
            </Col>
            <Col xs={12} md={5}>
              <Image src="./../images/credit-cards.svg" />
            </Col>
          </Row>
        </Container>

        <FooterDivider />

        <Container>
          <Row>
            <Col xs={12} md={6}>
              <p>&copy; 2022 TravelPinq. All rights reserved.</p>
            </Col>
            <Col xs={12} md={6}>
              <ul className="footer-bottom-list">
                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                <li>
                  <Link to={"#"}>Hotel</Link>
                </li>
                {/* <li>
                  <Link to={"#"}>Activity</Link>
                </li>
                <li>
                  <Link to={"#"}>Rental</Link>
                </li> */}
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
