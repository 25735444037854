import { Button, Container } from "react-bootstrap";
import HomePageSearch from "../includes/HomePageSearch";
import OfferSlider from "../includes/OfferSlider";
import PopularActivities from "../includes/PopularActivities";
import RecommendedHotels from "../includes/RecommendedHotels";
import TodayTopDeals from "../includes/TodayTopDeals";
import TopDestination from "../includes/TopDestination";
import Trending from "../includes/Trending";
import TrendingRental from "../includes/TrendingRental";
import WhyChoose from "../includes/WhyChoose";
import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../endpoints";
import { connect } from "react-redux";
import { Carousel } from 'react-bootstrap';

const Home = ({ setLandingPagesInitialState }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Handle window resize events
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Function to fetch home page data
    const getHomePage = useCallback(() => {
        axios.get(`${API_BASE_URL}landing`)
            .then(res => {
                if (res.status === 200) {
                    setLandingPagesInitialState(res.data);
                }
            })
            .catch(err => {
                console.error("Error fetching home page data:", err);
            });
    }, [setLandingPagesInitialState]);

    // Fetch home page data on component mount
    useEffect(() => {
        const accessToken = localStorage.getItem("token"); // Check the correct key for the token

        if (accessToken) {
            getHomePage();  // Log out if the token is missing
        } else {
            getHomePage();
        }
    }, [getHomePage]);

    return (
        <>
            <div className="home-page-slider mb-5">
                <span className="main-page-heading">Explore the world with a smile</span>
                <p className="main-page-sub-heading text-white mb-5">
                    Find awesome hotel, tour, car, and activities in pakistan
                </p>
                <HomePageSearch />
            </div>
            <div className="offer-top-destination-trending-section ms-5 mb-5    " style={{ width: '93%' }}>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <button
                            type="submit"
                            className="btn btn-danger btn-lg rounded-pill"
                            style={{ backgroundColor: "rgb(0, 129, 129)" }}
                        >
                            Explore Your Spot
                        </button>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-5 fw-bold">
                        <h1>
                            Every Journey is Unique
                        </h1>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-12 mt-3" style={{ paddingLeft: '70px' }}>
                        <p>Discover the beauty of Pakistan with us--adventure, culture, and unforgettable memories await!</p>
                        <button
                            type="submit"
                            className="btn btn-danger btn-lg rounded-pill"
                            style={{ backgroundColor: "rgb(0, 129, 129)" }}
                        >
                            Book Now
                        </button>
                    </div>
                </div>
            </div>
            <div className="offer-top-destination-trending-section ms-5" style={{ width: '93%' }}>
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div
                            className="card text-white card-has-bg click-col"
                            style={{
                                backgroundImage:
                                    "url('https://islamabad.comsats.edu.pk/assets/img/islamabad.jpeg')",
                            }}
                        >
                            <div className="card-img-overlay d-flex flex-column">
                                <div className="card-body" style={{ paddingTop: "65px", paddingBottom: "0px" }}>
                                    <h4 className="card-title mt-0" style={{ fontSize: "15px" }}>ISLAMABAD PAKISTAN CAPITAL</h4>
                                    <p style={{ fontSize: "13px" }}>
                                        In a City of Greenery, Modern Architecture, And A Gateway To the Majestic Margalla Hills.
                                    </p>
                                </div>
                                <div className="card-footer d-flex justify-content-end border-0">
                                    <div className="media">
                                        <i className="fas fa-arrow-right p-2 rounded-circle bg-secondary"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div
                            className="card text-white card-has-bg click-col"
                            style={{
                                backgroundImage:
                                    "url('https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Lahore_Fort_view_from_Baradari.jpg/800px-Lahore_Fort_view_from_Baradari.jpg')",
                            }}
                        >
                            <div className="card-img-overlay d-flex flex-column">
                                <div className="card-body" style={{ paddingTop: "65px", paddingBottom: "0px" }}>
                                    <h4 className="card-title mt-0" style={{ fontSize: "15px" }}>LET'S EXPLORE LAHORE TOGETHER</h4>
                                    <p style={{ fontSize: "13px" }}>
                                        Dive into Curated Travel Experiences, In-Depth Guides, and Personalized Planning Tools for Every Type of Traveler!
                                    </p>
                                </div>
                                <div className="card-footer d-flex justify-content-end border-0">
                                    <div className="media">
                                        <i className="fas fa-arrow-right p-2 rounded-circle bg-secondary"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div
                            className="card text-white card-has-bg click-col"
                            style={{
                                backgroundImage:
                                    "url('https://guidetopakistan.pk/wp-content/uploads/2024/08/Hunza-Valley-Header-1.png')",
                            }}
                        >
                            <div className="card-img-overlay d-flex flex-column">
                                <div className="card-body" style={{ paddingTop: "65px", paddingBottom: "0px" }}>
                                    <h4 className="card-title mt-0" style={{ fontSize: "15px" }}>NAVIGATE YOUR NEXT EXPERT GUIDES & PERSONALIZED TRIP IN HUNZA</h4>
                                    <p style={{ fontSize: "13px" }}>
                                        Hunza, Pakistan's crown jewel, captivates with its breathtaking valleys, majestic peaks, and a serene blend of nature's finest artistry.
                                    </p>
                                </div>
                                <div className="card-footer d-flex justify-content-end border-0">
                                    <div className="media">
                                        <i className="fas fa-arrow-right p-2 rounded-circle bg-secondary"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div
                            className="card text-white card-has-bg click-col"
                            style={{
                                backgroundImage:
                                    "url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDrmMmh_GN4D0kHntYFHfq-GbfEZE4rIvUqA&s')",
                            }}
                        >
                            <div className="card-img-overlay d-flex flex-column">
                                <div className="card-body" style={{ paddingTop: "65px", paddingBottom: "0px" }}>
                                    <h4 className="card-title mt-0" style={{ fontSize: "15px" }}>BEAUTY OF SWAT</h4>
                                    <p style={{ fontSize: "13px" }}>
                                        Swat, Pakistan paradise,boasts lush valleys, clear rivers, and majestic peaks.
                                    </p>
                                </div>
                                <div className="card-footer d-flex justify-content-end border-0">
                                    <div className="media">
                                        <i className="fas fa-arrow-right p-2 rounded-circle bg-secondary"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div
                className="offer-top-destination-trending-section ms-5"
                style={{ width: "93%" }}
            >
                <div className="row g-4 mt-2">
                    {/* First Column: Image */}
                    <div className="col-md-5">
                        <div className="image-section">
                            <img
                                src="./../images/rectangle-192740.png"
                                className="img-fluid rounded"
                                alt="Image"
                            />
                        </div>
                    </div>

                    <div className="col-md-7 d-flex flex-column justify-content-center ps-5">
                        <h2 className="display-7 mb-4">What type of trip are you planning?</h2>
                        <p className="text mb-4">
                            Find destinations in Pakistan that best match your interests
                        </p>

                        <div className="row g-3">
                            {/* Image 1 */}
                            <div className="col-6 col-sm-3 justify-content-center">
                                <img
                                    src="./../images/rectangle-192750.png"
                                    className="img-fluid rounded"
                                    alt="Image 1"
                                />
                                <h5 className="mt-2">Clifton, Karachi</h5>
                                <p>455 km away</p>
                            </div>
                            {/* Image 2 */}
                            <div className="col-6 col-sm-3 justify-content-center">
                                <img
                                    src="./../images/rectangle-192751.png"
                                    className="img-fluid rounded"
                                    alt="Image 2"
                                />
                                <h5 className="mt-2">Clifton, Karachi</h5>
                                <p>455 km away</p>
                            </div>
                            {/* Image 3 */}
                            <div className="col-6 col-sm-3 justify-content-center">
                                <img
                                    src="./../images/rectangle-192752.png"
                                    className="img-fluid rounded"
                                    alt="Image 3"
                                />
                                <h5 className="mt-2">Clifton, Karachi</h5>
                                <p>455 km away</p>
                            </div>
                            {/* Image 4 */}
                            <div className="col-6 col-sm-3 justify-content-center">
                                <img
                                    src="./../images/rectangle-192753.png"
                                    className="img-fluid rounded"
                                    alt="Image 4"
                                />
                                <h5 className="mt-2">Clifton, Karachi</h5>
                                <p>455 km away</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="offer-top-destination-trending-section ms-5" style={{ width: '93%' }}>
                <div className="row mt-5">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h4 className="fw-bold">Offers</h4>
                        <p>Promotions, Deals and special offers For you.</p>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                        <Carousel>
                            <Carousel.Item>
                                <div className="row g-3">
                                    <div className="col-sm-6 bg-white text-dark rounded d-flex">
                                        <div className="media d-flex p-3">
                                            <img src="https://thumbs.dreamstime.com/b/special-offer-37086976.jpg" className="mr-3 img-fluid" alt="First testimonial" />
                                            <div className="media-body ms-2">
                                                <h5>Lorem ipsum dollar</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
                                                <button
                                                    type="submit"
                                                    className="btn btn-sm btn-danger btn-lg rounded-pill"
                                                    style={{ backgroundColor: "rgb(0, 129, 129)", fontSize: "small !important" }}
                                                >
                                                    Explore Deals
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 bg-white text-dark rounded d-flex">
                                        <div className="media d-flex p-3">
                                            <img src="https://thumbs.dreamstime.com/b/special-offer-37086976.jpg" className="mr-3 img-fluid" alt="Second testimonial" />
                                            <div className="media-body ms-2">

                                                <h5>Lorem ipsum dollar</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
                                                <button
                                                    type="submit"
                                                    className="btn btn-sm btn-danger btn-lg rounded-pill"
                                                    style={{ backgroundColor: "rgb(0, 129, 129)", fontSize: "small !important" }}
                                                >
                                                    Explore Deals
                                                </button>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="row g-3">
                                    <div className="col-sm-6 bg-white text-dark rounded d-flex">
                                        <div className="media d-flex p-3">
                                            <img src="https://thumbs.dreamstime.com/b/special-offer-37086976.jpg" className="mr-3 img-fluid" alt="Third testimonial" />
                                            <div className="media-body ms-2">

                                                <h5>Lorem ipsum dollar</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
                                                <button
                                                    type="submit"
                                                    className="btn btn-sm btn-danger btn-lg rounded-pill"
                                                    style={{ backgroundColor: "rgb(0, 129, 129)", fontSize: "small !important" }}
                                                >
                                                    Explore Deals
                                                </button>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 bg-white text-dark rounded d-flex">
                                        <div className="media d-flex p-3">
                                            <img src="https://thumbs.dreamstime.com/b/special-offer-37086976.jpg" className="mr-3 img-fluid" alt="Fourth testimonial" />
                                            <div className="media-body ms-2">

                                                <h5>Lorem ipsum dollar</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
                                                <button
                                                    type="submit"
                                                    className="btn btn-sm btn-danger btn-lg rounded-pill"
                                                    style={{ backgroundColor: "rgb(0, 129, 129)", fontSize: "small !important" }}
                                                >
                                                    Explore Deals
                                                </button>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="row g-3">
                                    <div className="col-sm-6 bg-white text-dark rounded d-flex">
                                        <div className="media d-flex p-3">
                                            <img src="https://thumbs.dreamstime.com/b/special-offer-37086976.jpg" className="mr-3 img-fluid" alt="Fifth testimonial" />
                                            <div className="media-body ms-2">

                                                <h5>Lorem ipsum dollar</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
                                                <button
                                                    type="submit"
                                                    className="btn btn-sm btn-danger btn-lg rounded-pill"
                                                    style={{ backgroundColor: "rgb(0, 129, 129)", fontSize: "small !important" }}
                                                >
                                                    Explore Deals
                                                </button>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 bg-white text-dark rounded d-flex">
                                        <div className="media d-flex p-3">
                                            <img src="https://thumbs.dreamstime.com/b/special-offer-37086976.jpg" className="mr-3 img-fluid" alt="Sixth testimonial" />
                                            <div className="media-body ms-2">

                                                <h5>Lorem ipsum dollar</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
                                                <button
                                                    type="submit"
                                                    className="btn btn-sm btn-danger btn-lg rounded-pill"
                                                    style={{ backgroundColor: "rgb(0, 129, 129)", fontSize: "small !important" }}
                                                >
                                                    Explore Deals
                                                </button>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>
            <div
                className="offer-top-destination-trending-section ms-5"
                style={{ width: "93%" }}
            >
                <div className="row g-4 mt-2">
                    <h3 className="display-7">Travellers Point</h3>

                    {/* First Column: Image */}
                    <div className="col-md-5">
                        <div className="image-section">
                            <img
                                src="./../images/group-340.svg"
                                className="img-fluid rounded"
                                alt="Image"
                            />
                        </div>
                    </div>

                    <div className="col-md-7 d-flex flex-column ps-5">
                        <h6 className="display-7" style={{ color: "rgb(0, 129, 129)", fontWeight: "bold" }}>We help to find your dream place.</h6>
                        <h2 className="display-7 mb-4">What type of trip are you planning?</h2>
                        <p className="text mb-4">
                            At Travel Pin, we make finding your dream trip effortless. Whether you’re seeking adventure,
                            relaxation, or cultural exploration, we help you discover destinations that match your desires.
                            From breathtaking landscapes to unforgettable experiences, your perfect getaway is just a
                            click away!
                        </p>

                        <div className="row g-3">
                            {/* Image 1 */}
                            <div className="col-6 col-sm-3">
                                <h5 className="mt-2" style={{ color: "rgb(0, 129, 129)", fontWeight: "bold" }}>100+</h5>
                                <p>Holiday Package</p>
                            </div>

                            {/* Image 2 */}
                            <div className="col-6 col-sm-3">
                                <h5 className="mt-2" style={{ color: "rgb(0, 129, 129)", fontWeight: "bold" }}>172</h5>
                                <p>Hotels</p>
                            </div>
                            {/* Image 3 */}
                        </div>

                        <div className="row g-3">
                            <div className="col-6 col-sm-3">
                                <h5 className="mt-2" style={{ color: "rgb(0, 129, 129)", fontWeight: "bold" }}>68</h5>
                                <p>Elite Transportation</p>
                            </div>
                            <div className="col-6 col-sm-3">
                                <h5 className="mt-2" style={{ color: "rgb(0, 129, 129)", fontWeight: "bold" }}>1M+</h5>
                                <p>we help to find your dream place</p>
                            </div>
                        </div>

                        <div className="mt-4">
                            <button
                                type="submit"
                                className="btn btn-sm btn-danger btn-lg rounded-pill"
                                style={{ backgroundColor: "rgb(0, 129, 129)", fontSize: "small !important" }}
                            >
                                Explore Deals
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="offer-top-destination-trending-section ms-5"
                style={{ width: "93%" }}
            >
                <div className="row g-4 mt-2">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h4 className="fw-bold">Browse By Property Type :</h4>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div className="blog-item p-4">
                            <div className="blog-img mb-4">
                                <img src="./../images/rectangle-192760.png" className="img-fluid w-100 rounded" alt="Img not Found" />
                                <h5 className="card-title p-2">Hotels</h5>

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div className="blog-item p-4">
                            <div className="blog-img mb-4">
                                <img src="./../images/rectangle-192761.png" className="img-fluid w-100 rounded" alt="Img not Found" />
                                <h5 className="card-title p-2">Apartments</h5>

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div className="blog-item p-4">
                            <div className="blog-img mb-4">
                                <img src="./../images/rectangle-192762.png" className="img-fluid w-100 rounded" alt="Img not Found" />
                                <h5 className="card-title p-2">Villas</h5>

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div className="blog-item p-4">
                            <div className="blog-img mb-4">
                                <img src="./../images/rectangle-192763.png" className="img-fluid w-100 rounded" alt="Img not Found" />
                                <h5 className="card-title p-2">Gambling Sites</h5>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="offer-top-destination-trending-section ms-5"
                style={{ width: "93%" }}
            >
                <div className="row g-4 mt-2">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h4 className="fw-bold">Stay at our top unique properties</h4>
                        <p>From castles and villas to boats and igloos, we've got it all.</p>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div class="service-item bg-white rounded">
                            <div class="service-img">
                                <img src="./../images/rectangle-50.png" class="img-fluid rounded-top w-100 p-2" alt="Image" />
                            </div>
                            <div class="rounded-bottom p-4">
                                <h5 class="d-inline-block mb-4">KASHMIR,PAKISTAN</h5>
                                <br />
                                <strong style={{ color: "rgb(0, 129, 129)" }}>Superb,</strong> 1300 reviews.<br></br>
                                <strong style={{ textDecoration: "line-through" }}>PKR 68,220</strong> <strong style={{ color: "rgb(0, 129, 129)" }}>PKR 68,220</strong> <strong>2 Days to Go</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div class="service-item bg-white rounded">
                            <div class="service-img">
                                <img src="./../images/rectangle-51.png" class="img-fluid rounded-top w-100 p-2" alt="Image" />
                            </div>
                            <div class="rounded-bottom p-4">
                                <h5 class="d-inline-block mb-4">KASHMIR,PAKISTAN</h5>
                                <br />
                                <strong style={{ color: "rgb(0, 129, 129)" }}>Superb,</strong> 1300 reviews.<br></br>
                                <strong style={{ textDecoration: "line-through" }}>PKR 68,220</strong> <strong style={{ color: "rgb(0, 129, 129)" }}>PKR 68,220</strong> <strong>2 Days to Go</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div class="service-item bg-white rounded">
                            <div class="service-img">
                                <img src="./../images/rectangle-52.png" class="img-fluid rounded-top w-100 p-2" alt="Image" />
                            </div>
                            <div class="rounded-bottom p-4">
                                <h5 class="d-inline-block mb-4">KASHMIR,PAKISTAN</h5>
                                <br />
                                <strong style={{ color: "rgb(0, 129, 129)" }}>Superb,</strong> 1300 reviews.<br></br>
                                <strong style={{ textDecoration: "line-through" }}>PKR 68,220</strong> <strong style={{ color: "rgb(0, 129, 129)" }}>PKR 68,220</strong> <strong>2 Days to Go</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div class="service-item bg-white rounded">
                            <div class="service-img">
                                <img src="./../images/rectangle-53.png" class="img-fluid rounded-top w-100 p-2" alt="Image" />
                            </div>
                            <div class="rounded-bottom p-4">
                                <h5 class="d-inline-block mb-4">KASHMIR,PAKISTAN</h5>
                                <br />
                                <strong style={{ color: "rgb(0, 129, 129)" }}>Superb,</strong> 1300 reviews.<br></br>
                                <strong style={{ textDecoration: "line-through" }}>PKR 68,220</strong> <strong style={{ color: "rgb(0, 129, 129)" }}>PKR 68,220</strong> <strong>2 Days to Go</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="offer-top-destination-trending-section ms-5"
                style={{ width: "93%" }}
            >
                <div className="row g-4 mt-2">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h4 className="fw-bold">Homes guests love</h4>
                        <p>discover home.</p>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div class="service-item bg-white rounded">
                            <div class="service-img">
                                <img src="./../images/rectangle-192764.png" class="img-fluid rounded-top w-100 p-2" alt="Image" />
                            </div>
                            <div class="rounded-bottom p-4">
                                <h5 class="d-inline-block mb-4">KASHMIR,PAKISTAN</h5>
                                <br />
                                <strong style={{ color: "rgb(0, 129, 129)" }}>Superb,</strong> 1300 reviews.<br></br>
                                <strong style={{ textDecoration: "line-through" }}>PKR 68,220</strong> <strong style={{ color: "rgb(0, 129, 129)" }}>PKR 68,220</strong> <strong>2 Days to Go</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div class="service-item bg-white rounded">
                            <div class="service-img">
                                <img src="./../images/rectangle-192765.png" class="img-fluid rounded-top w-100 p-2" alt="Image" />
                            </div>
                            <div class="rounded-bottom p-4">
                                <h5 class="d-inline-block mb-4">KASHMIR,PAKISTAN</h5>
                                <br />
                                <strong style={{ color: "rgb(0, 129, 129)" }}>Superb,</strong> 1300 reviews.<br></br>
                                <strong style={{ textDecoration: "line-through" }}>PKR 68,220</strong> <strong style={{ color: "rgb(0, 129, 129)" }}>PKR 68,220</strong> <strong>2 Days to Go</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div class="service-item bg-white rounded">
                            <div class="service-img">
                                <img src="./../images/rectangle-192766.png" class="img-fluid rounded-top w-100 p-2" alt="Image" />
                            </div>
                            <div class="rounded-bottom p-4">
                                <h5 class="d-inline-block mb-4">KASHMIR,PAKISTAN</h5>
                                <br />
                                <strong style={{ color: "rgb(0, 129, 129)" }}>Superb,</strong> 1300 reviews.<br></br>
                                <strong style={{ textDecoration: "line-through" }}>PKR 68,220</strong> <strong style={{ color: "rgb(0, 129, 129)" }}>PKR 68,220</strong> <strong>2 Days to Go</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div class="service-item bg-white rounded">
                            <div class="service-img">
                                <img src="./../images/rectangle-192767.png" class="img-fluid rounded-top w-100 p-2" alt="Image" />
                            </div>
                            <div class="rounded-bottom p-4">
                                <h5 class="d-inline-block mb-4">KASHMIR,PAKISTAN</h5>
                                <br />
                                <strong style={{ color: "rgb(0, 129, 129)" }}>Superb,</strong> 1300 reviews.<br></br>
                                <strong style={{ textDecoration: "line-through" }}>PKR 68,220</strong> <strong style={{ color: "rgb(0, 129, 129)" }}>PKR 68,220</strong> <strong>2 Days to Go</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="offer-top-destination-trending-section ms-5 mt-5" style={{ width: '93%' }}>
                {/* <WhyChoose /> */}
                <TodayTopDeals />
                <RecommendedHotels />
                <PopularActivities />
                <TrendingRental />
            </div>
            <div className="offer-top-destination-trending-section ms-5" style={{ width: '93%' }}>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                        <h4 className="fw-bold">Best event’s for 2024</h4>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                        <div className="row full-background-section text-white"  style={{
                                backgroundImage:
                                    "url('./../images/rectangle-192770.png')",backgroundRepeat:"no-repeat",borderRadius:"12px"
                            }}>
                            <div className="text-content" style={{ borderRadius:"30px",padding:"9% 6%" }}>
                                <h3 style={{ color: 'white', fontFamily: 'Mestika', fontSize: '14vh', paddingLeft: '20px' }}>
                                    Hunza Valley<br /> Pakistan
                                </h3>
                                <p>Dive into Curated Travel Experiences, In-Depth Guides, <br/>
                                and Personalized Planning Tools for Every Type of Traveler!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="offer-top-destination-trending-section ms-5" style={{ width: '93%' }}>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                        <h4 className="fw-bold">Get inspiration for your next trip</h4>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                        <div
                            className="card text-white card-has-bg click-col"
                            style={{
                                backgroundImage:
                                    "url('https://www.openislamabad.org/wp-content/uploads/2018/10/Centaurus-Towers.jpg')",
                            }}
                        >
                            <div className="card-img-overlay d-flex flex-column">
                                <div className="card-body" style={{ paddingTop: "65px", paddingBottom: "0px" }}>
                                    <h4 className="card-title mt-0" style={{ fontSize: "15px" }}>Islamabad, Pakistan's capital</h4>
                                    <p style={{ fontSize: "13px" }}>
                                        Is a city of stunning greenery, modern architecture, and a
                                        gateway to the majestic Margalla Hills.
                                    </p>
                                </div>
                                <div className="card-footer d-flex justify-content-end border-0">
                                    <div className="media">
                                        <i className="fas fa-heart me-2 p-2 rounded-circle bg-secondary"></i>
                                        <i className="fas fa-arrow-right p-2 rounded-circle bg-secondary"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                        <div
                            className="card text-white card-has-bg click-col"
                            style={{
                                backgroundImage:
                                    "url('https://www.dreamsmarketing.pk/images/post/169245142564e0c26161878.jpg')",
                            }}
                        >
                            <div className="card-img-overlay d-flex flex-column">
                                <div className="card-body" style={{ paddingTop: "65px", paddingBottom: "0px" }}>
                                    <h4 className="card-title mt-0" style={{ fontSize: "15px" }}>Islamabad, Pakistan's capital</h4>
                                    <p style={{ fontSize: "13px" }}>
                                        Is a city of stunning greenery, modern architecture, and a
                                        gateway to the majestic Margalla Hills.
                                    </p>
                                </div>
                                <div className="card-footer d-flex justify-content-end border-0">
                                    <div className="media">
                                        <i className="fas fa-heart me-2 p-2 rounded-circle bg-secondary"></i>
                                        <i className="fas fa-arrow-right p-2 rounded-circle bg-secondary"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                        <div
                            className="card text-white card-has-bg click-col"
                            style={{
                                backgroundImage:
                                    "url('https://propakistani.pk/wp-content/uploads/2024/12/islamabad-weather-.jpg')",
                            }}
                        >
                            <div className="card-img-overlay d-flex flex-column">
                                <div className="card-body" style={{ paddingTop: "65px", paddingBottom: "0px" }}>
                                    <h4 className="card-title mt-0" style={{ fontSize: "15px" }}>Islamabad, Pakistan's capital</h4>
                                    <p style={{ fontSize: "13px" }}>
                                        Is a city of stunning greenery, modern architecture,
                                        and a gateway to the majestic Margalla Hills.
                                    </p>
                                </div>
                                <div className="card-footer d-flex justify-content-end border-0">
                                    <div className="media">
                                        <i className="fas fa-heart me-2 p-2 rounded-circle bg-secondary"></i>
                                        <i className="fas fa-arrow-right p-2 rounded-circle bg-secondary"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                        <div
                            className="card text-white card-has-bg click-col"
                            style={{
                                backgroundImage:
                                    "url('https://mediaim.expedia.com/destination/1/ff336fd63f692dce102ad19a6ecc52c2.jpg')",
                            }}
                        >
                            <div className="card-img-overlay d-flex flex-column">
                                <div className="card-body" style={{ paddingTop: "65px", paddingBottom: "0px" }}>
                                    <h4 className="card-title mt-0" style={{ fontSize: "15px" }}>Islamabad, Pakistan's capital</h4>
                                    <p style={{ fontSize: "13px" }}>
                                        Is a city of stunning greenery, modern architecture,
                                        and a gateway to the majestic Margalla Hills.
                                    </p>
                                </div>
                                <div className="card-footer d-flex justify-content-end border-0">
                                    <div className="media">
                                        <i className="fas fa-heart me-2 p-2 rounded-circle bg-secondary"></i>
                                        <i className="fas fa-arrow-right p-2 rounded-circle bg-secondary"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                        <div
                            className="card text-white card-has-bg click-col"
                            style={{
                                backgroundImage:
                                    "url('https://amanah.pk/wp-content/uploads/2023/10/WhatsApp-Image-2023-10-19-at-07.45.12_dde9f763.jpg')",
                            }}
                        >
                            <div className="card-img-overlay d-flex flex-column">
                                <div className="card-body" style={{ paddingTop: "65px", paddingBottom: "0px" }}>
                                    <h4 className="card-title mt-0" style={{ fontSize: "15px" }}>Islamabad, Pakistan's capital</h4>
                                    <p style={{ fontSize: "13px" }}>
                                        Is a city of stunning greenery, modern architecture,
                                        and a gateway to the majestic Margalla Hills.
                                    </p>
                                </div>
                                <div className="card-footer d-flex justify-content-end border-0">
                                    <div className="media">
                                        <i className="fas fa-heart me-2 p-2 rounded-circle bg-secondary"></i>
                                        <i className="fas fa-arrow-right p-2 rounded-circle bg-secondary"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {/* <div className="offer-top-destination-trending-section">
                <OfferSlider />
                <TopDestination />
                <Trending />
            </div> */}

            {/* <div className="travel-tip-section">
                <h2 className="text-white">Travel Tips</h2>
                <p className="text-white" style={{ maxWidth: "80%", textAlign: "center" }}>
                    Northern Ireland’s is now contingent. Britain is getting a divorce. Northern Ireland is being offered a trial separation for Britain there is a one.
                </p>
                <Button variant="outline-light" size="lg">Book Now</Button>
            </div> */}
        </>
    );
};

const mapStateToProps = (state) => ({
    // Map state to props if needed
});

const dispatchStateToProps = (dispatch) => ({
    setLandingPagesInitialState: (data) => {
        dispatch({
            type: "UPDATE_LANDING_PAGES_INITIAL_STATE",
            payload: data,
        });
    },
});

// Connect component to Redux store
export default connect(mapStateToProps, dispatchStateToProps)(Home);
